export const { MEDIA_ROOT } = process.env;
export const { API_ENDPOINT } = process.env;

export const { MERCURE_HUB } = process.env;

export const endpoint = {
  token: `${API_ENDPOINT}/token`,
  refreshToken: `${API_ENDPOINT}/token/refresh`,
  holding: {
    list: `${API_ENDPOINT}/holdings`,
  },
  enterprises: {
    list: `${API_ENDPOINT}/enterprises`,
    one: (id: number | string): string => `${API_ENDPOINT}/enterprises/${id}`,
    defaultData: (id: number | string): string => endpoint.enterprises.one(id) + '/create_default_data',
  },
  permissions: {
    list: `${API_ENDPOINT}/permissions`,
    one: (id: number | string): string => `${API_ENDPOINT}/permissions/${id}`,
  },
  companies: {
    list: `${API_ENDPOINT}/companies`,
    one: (id: number | string): string => `${API_ENDPOINT}/companies/${id}`,
  },
  contacts: {
    list: `${API_ENDPOINT}/contacts`,
  },
  staff: {
    list: `${API_ENDPOINT}/staff`,
    one: (id: number | string): string => `${API_ENDPOINT}/staff/${id}`,
    donut: (id: number | string): string => `${API_ENDPOINT}/staff/${id}/donut`,
  },
  tasks: {
    list: `${API_ENDPOINT}/tasks`,
    one: (id: number | string): string => `${API_ENDPOINT}/tasks/${id}`,
    log: (id: number | string): string => `${API_ENDPOINT}/tasks/logs?objectId=${id}`,
    status: {
      list: `${API_ENDPOINT}/task_statuses`,
      one: (id: number | string): string => `${API_ENDPOINT}/task_statuses/${id}`,
    },
  },
  users: {
    list: `${API_ENDPOINT}/users`,
    register: `${API_ENDPOINT}/users/register`,
    one: (id: number | string): string => `${API_ENDPOINT}/users/${id}`,
  },
  groups: {
    list: `${API_ENDPOINT}/user_groups`,
    one: (id: number | string): string => `${API_ENDPOINT}/user_groups/${id}`,
  },
  comments: {
    list: `${API_ENDPOINT}/comments`,
    one: (id: number | string): string => `${API_ENDPOINT}/comments/${id}`,
  },
  notifications: {
    list: `${API_ENDPOINT}/notifications`,
    one: (id: number | string): string => `${API_ENDPOINT}/notifications/${id}`,
  },
  packKinds: {
    list: `${API_ENDPOINT}/pack_kinds`,
    one: (id: number | string): string => `${API_ENDPOINT}/pack_kinds/${id}`,
  },
  packTypes: {
    list: `${API_ENDPOINT}/pack_types`,
    one: (id: number | string): string => `${API_ENDPOINT}/pack_types/${id}`,
  },
  products: {
    list: `${API_ENDPOINT}/products`,
    one: (id: number | string): string => `${API_ENDPOINT}/products/${id}`,
    storeConditions: (id: number | string): string => `${API_ENDPOINT}/products/${id}/store_conditions`,
    prices: (id: number | string): string => `${API_ENDPOINT}/products/${id}/prices`,
    attributes: (id: number | string): string => `${API_ENDPOINT}/products/${id}/attributes`,
    packs: (id: number | string): string => `${API_ENDPOINT}/products/${id}/packs`,
    codes: (id: number | string): string => `${API_ENDPOINT}/products/${id}/codes`,
    localizations: (id: number | string): string => `${API_ENDPOINT}/products/${id}/localizations`,
  },
  manufacturer: {
    list: `${API_ENDPOINT}/manufacturers`,
    one: (id: number | string): string => `${API_ENDPOINT}/manufacturers/${id}`,
  },
  brand: {
    list: `${API_ENDPOINT}/brands`,
    one: (id: number | string): string => `${API_ENDPOINT}/brands/${id}`,
    brandlines: (id: number | string): string => `${API_ENDPOINT}/brands/${id}/brandlines`,
  },
  brandline: {
    list: `${API_ENDPOINT}/brandlines`,
    one: (id: number | string): string => `${API_ENDPOINT}/brandlines/${id}`,
  },
  media: {
    root: `${MEDIA_ROOT}`,
    one: (id: number | string) => `${API_ENDPOINT}/media_objects/${id}`,
    list: `${API_ENDPOINT}/media_objects`,
  },
  tags: {
    list: `${API_ENDPOINT}/tags`,
    one: (id: number | string): string => `${API_ENDPOINT}/tags/${id}`,
  },
  worklog: {
    one: (id: number) => `${API_ENDPOINT}/work_logs/${id}`,
    list: `${API_ENDPOINT}/work_logs`,
    report: {
      list: `${API_ENDPOINT}/reports/work_logs`,
      total: `${API_ENDPOINT}/reports/work_logs/total`,
      excel: `${API_ENDPOINT}/reports/work_logs/xlsx`,
    }
  },
};
