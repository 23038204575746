import React, {FC, memo, PropsWithChildren, useEffect, useMemo, useState} from 'react'
import EventSourcePolyfill from 'eventsource'
import {MercureContext} from './MercureContext'


type Props = PropsWithChildren<{
  hub: string,
  token: string,
  topics: string[],
  onMessage: {
    [type: string]: (message: any) => any,
  },
}>;

export type MercureMessage = {
  '@type': string,
  '@id': string,
  '@context': string,
}

const MercureSubscriber: FC<Props> = ({
                                        hub,
                                        topics,
                                        token,
                                        onMessage,
                                        children,
                                      }) => {
  const [_, setEvents] = useState<MercureMessage[]>([])
  const contextValue = useMemo(() => ({hub, token}), [hub, token])
  const [lastEventId, setLastEventId] = useState<string | null>(null)

  const eventSource = useMemo(() => {
    if (hub && token) {
      const url = new URL(hub)

      topics.forEach((topic) => {
        url.searchParams.append('topic', topic)
      })
      return new EventSourcePolyfill(url.toString(), {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    }

    return null
  }, [hub, topics, token])

  useEffect(() => {
    if (eventSource) {
      eventSource.onmessage = (event) => {
        try {
          console.log(event)
          const message = JSON.parse(event.data) as MercureMessage;

          setEvents((prev) => [...prev, message])
          setLastEventId(event.lastEventId);
          Object.keys(onMessage).forEach((key: string) => {
            if (message['@id']?.includes(key)) {
              onMessage[key](message)
            }
          })
        } catch (error) {
          console.log(`mercure error = ${error}`)
        }
      }

      return () => {
        eventSource.close()
      }
    }

    return () => null
  }, [eventSource, onMessage])

  return (
    <MercureContext.Provider value={contextValue}>
      {children}
    </MercureContext.Provider>
  )
}

export default memo(MercureSubscriber, ((oldProps, newProps) => {
  const difference = newProps.topics
    .filter((x) => !oldProps.topics.includes(x))
    .concat(oldProps.topics.filter((x) => !newProps.topics.includes(x)))

  return difference.length === 0
    || oldProps.hub === newProps.hub
    || oldProps.token === newProps.token
}))
