import { createContext } from 'react'

export interface MercureContextProps {
  hub: string,
  token: string,
  withCredentials?: boolean
}

export const MercureContext = createContext<MercureContextProps>({
  hub: '',
  token: '',
  withCredentials: false,
})
