import {eventbus} from "@lib/eventBus/eventbus";
import {eventBus} from "@lib/eventBus/index";
import {ITask} from "@lib/entity";

export const taskEventChannel = eventbus<{
  onTaskChange: (payload: ITask) => void
  onTaskDelete: (iri: string) => void
}>()

eventBus.on('onMercureMessageReceive', (message: Partial<ITask>) => {
  if (message["@type"] === 'Task') { //create || update
    taskEventChannel.emit("onTaskChange", message as ITask)
  }

  if (message['@type'] === undefined && message['@id'] && message['@id']?.includes('task')) { //delete
    taskEventChannel.emit('onTaskDelete', message['@id'])
  }
})
