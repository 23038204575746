import React from "react";
import {BrowserRouter as Router} from 'react-router-dom'
import {Provider as MobxProvider} from "mobx-react";
import mainStore from '@stores/mainStore';
import optionsStore from '@stores/optionsStore';
import Layout from '@components/LayoutComponents/Layout';
import networkService from './config/client';
import {ConfigProvider} from 'antd';
import antdRU from 'antd/es/locale/ru_RU'

import "./assets/scss/theme.scss";

export const stores = {
  mainStore,
  optionsStore,
  appStore: mainStore.appStore,
}

networkService.setupInterceptors(stores);

const App: React.FC = () => {
  return (
    <MobxProvider {...stores}>
      <ConfigProvider locale={antdRU}>
        <Router>
          <Layout/>
        </Router>
      </ConfigProvider>
    </MobxProvider>
  );
}

export default App;
