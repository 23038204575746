import {axiosInstance} from '../client'
import {endpoint} from '../endpoint'
import {IWorkLog} from "@lib/entity/IWorkLog";
import {serializeParamOrderRequest} from "@config/format";

export type WorklogFilter = {
  staff?: string | number,
  task?: string | number,
  'exists[endAt]'?: boolean,
}

export interface IWorkLogListResponse {
  'hydra:member': IWorkLog[],
  'hydra:totalItems': number
}

export default {
  list: (order = {}, filter: WorklogFilter = {}, page = 1, itemsPerPage = 100) => axiosInstance<IWorkLogListResponse>({
    method: 'get',
    url: endpoint.worklog.list,
    params: {
      page,
      itemsPerPage,
      ...serializeParamOrderRequest(order),
      ...filter,
    },
  }),
  report:
    {
      list: (order = {}, filter: WorklogFilter = {}, page = 1, itemsPerPage = 100) => axiosInstance<IWorkLogListResponse>({
        method: 'get',
        url: endpoint.worklog.report.list,
        params: {
          page,
          itemsPerPage,
          ...serializeParamOrderRequest(order),
          ...filter,
        },
      }),
      total: (filter: WorklogFilter = {}) => axiosInstance<IWorkLogListResponse>({
        method: 'get',
        url: endpoint.worklog.report.total,
        params: {
          ...filter,
        },
      }),
      excel: (filter: { [p: string]: any }) => axiosInstance({
        method: 'get',
        url: endpoint.worklog.report.excel,
        responseType: 'blob',
        params: {
          ...filter,
        },
      }),
    },
  get: (id: number) => axiosInstance({
    method: 'get',
    url: endpoint.worklog.one(id),
  }),
  update: (id: number, fields: {
    startAt?: string,
    endAt?: string,
    task?: string,
    staff?: string,
    description?: string
  }) => axiosInstance({
    method: 'put',
    url: endpoint.worklog.one(id),
    data: {
      ...fields,
    },
  }),
  remove: (entity: IWorkLog) => axiosInstance({
    method: 'delete',
    url: endpoint.worklog.one(entity.id),
  }),
  create: (fields: {
    startAt: string,
    endAt?: string,
    task: string,
    staff: string,
    description?: string
  }) => axiosInstance({
    method: 'post',
    url: endpoint.worklog.list,
    data: {
      ...fields,
    },
  }),
}
