import React from 'react'
// import { Link } from 'react-router-dom'
import { Result, Button } from 'antd'


const NotFoundPage = () => (
  <Result
    status="404"
    title="404"
    subTitle={(
      <div>
        <div>Страница, которую вы ищете, не существует, возможно, она была перемещена или вообще удалена.</div>
      </div>
    )}
    extra={(
      // <Link to="/">
        <Button type="primary">На главную</Button>
      // </Link>
    )}
  />
)

export default NotFoundPage
