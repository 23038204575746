import duration from 'dayjs/plugin/duration'
import weekday from 'dayjs/plugin/weekday'
import week from 'dayjs/plugin/isoWeek'

import humanize from 'humanize-duration'
import dayjs from 'dayjs'

export const defaultLanguage = 'ru'

dayjs.extend(duration)
dayjs.extend(weekday)
dayjs.extend(week)

export const serializeParamOrderRequest = (order: object) => {
  const orderParams = {}

  Object.keys(order).forEach((key) => {
    // @ts-ignore
    orderParams[`order[${key}]`] = order[key]
  })

  return orderParams
}

export function dateLocalization(format: string) {
  switch (format) {
    case 'DD.MM.YYг. в h:mm':
      return 'DD.MM.YY at h:mm'
    default:
      return format
  }
}

export const dateFormat = (value: string | number | dayjs.Dayjs, format: string | undefined = undefined) => dayjs(value)
  .format(dateLocalization(format ?? '') || 'DD.MM.YYYY HH:mm')

export const humanizeDuration = (minutes: number) => {
  if (minutes <= 0) {
    return '< 1 минуты'
  }
  return humanize(minutes * 60 * 1000, { language: defaultLanguage })
}

export const getIdFormIri = (iri: string): string => iri.split('/').reverse()[0]
