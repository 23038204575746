import React from 'react'
import { inject, observer } from 'mobx-react'
import { Redirect, Route } from 'react-router-dom'


export default inject("appStore")(observer(({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (rest.appStore.auth.isLoggedIn === true ? <Component {...props} /> : <Redirect to="/login" />)}
  />
)))
