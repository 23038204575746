import { autorun, makeAutoObservable } from "mobx";
import { axiosInstance } from "@config/client";
import { endpoint } from "@config/endpoint";
import jwt_decode from "jwt-decode";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {IStaff} from "@lib/entity";

export interface IAuthState {
  token: string | null,
  staff: IStaff | null,
  isLoggedIn: boolean,
  expiresIn: string | null,
  refreshToken: string | null,
  login?: string | null,
  roles: string[] | undefined,
  status: number | undefined,
  message: string | undefined
}


export default class AppStore {
  isInit = false;
  auth: IAuthState = {
    token: null,
    staff: null,
    isLoggedIn: false,
    expiresIn: null,
    refreshToken: null,
    login: null,
    roles: [],
    status: null
  };

  constructor() {
    autorun(async () => {
      this.init();
    });

    makeAutoObservable(this);
  }

  init() {
    this.auth.token = localStorage.getItem("app.token");
    this.auth.refreshToken = localStorage.getItem("app.refreshToken");
    this.auth.staff = localStorage.getItem("app.staff") ? JSON.parse(localStorage.getItem("app.staff")) : "";
    this.auth.expiresIn = localStorage.getItem("app.expiresIn");
    this.auth.login = localStorage.getItem("app.login");
    this.auth.roles = localStorage.getItem("app.roles")?.split(",");
    this.auth.isLoggedIn = Boolean(this.auth.token && localStorage.getItem("app.isLoggedIn"));

    if (this.auth.token) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${this.auth.token}`;
    }
  }

  loginRequest(login: string, password: string) {
    axiosInstance.defaults.headers.common.Authorization = null;
    axiosInstance.post(endpoint.token, {
      login,
      password,
    }, { headers: { "Authorization": null } } as AxiosRequestConfig).then((response: AxiosResponse) => {
      const decoded = jwt_decode(response.data.token) as { staff: IStaff, username: string, roles: string[] };

      localStorage.setItem("app.token", response.data.token);
      localStorage.setItem("app.staff", decoded.staff ? JSON.stringify(decoded.staff) : "");
      localStorage.setItem("app.isLoggedIn", "1");
      localStorage.setItem("app.login", decoded.username);
      localStorage.setItem("app.roles", decoded.roles.join(","));

      axiosInstance.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;

      this.auth = {
        token: response.data.token,
        isLoggedIn: true,
        expiresIn: null,
        refreshToken: null,
        login: decoded.username,
        roles: decoded.roles,
        staff: decoded.staff,
        status: undefined
      };

    }).catch((error: AxiosError) => {
      console.log(error);
      this.auth = {
        token: null,
        staff: null,
        isLoggedIn: false,
        expiresIn: null,
        refreshToken: null,
        login: null,
        roles: [],
        status: error.response?.status ?? 500,
        // @ts-ignore
        message: error.response?.data['hydra:description']
      };
      localStorage.removeItem("app.token");
      localStorage.removeItem("app.staff");
      localStorage.removeItem("app.isLoggedIn");
      localStorage.removeItem("app.login");
      localStorage.removeItem("app.roles");
    });

  }

  registerRequest(name: string, login: string, password: string) {
    axiosInstance.post(endpoint.holding.list, {
      name,
      login,
      password,
    }, {}).then(() => {

      this.loginRequest(login, password);

    }).catch((error) => {
      console.log(error);
      this.auth = {
        token: null,
        staff: null,
        isLoggedIn: false,
        expiresIn: null,
        refreshToken: null,
        login: null,
        roles: [],
        status: error.response?.status ?? 500,
        // @ts-ignore
        message: error.response?.data['hydra:description']
      };
      localStorage.removeItem("app.token");
      localStorage.removeItem("app.staff");
      localStorage.removeItem("app.isLoggedIn");
      localStorage.removeItem("app.login");
      localStorage.removeItem("app.roles");
    });
  }

  logout() {
    this.auth = {
      token: null,
      staff: null,
      isLoggedIn: false,
      expiresIn: null,
      refreshToken: null,
      login: null,
      roles: [],
    };

    localStorage.removeItem("app.token");
    localStorage.removeItem("app.staff");
    localStorage.removeItem("app.isLoggedIn");
    localStorage.removeItem("app.login");
    localStorage.removeItem("app.roles");
  }

  resetErrorCode() {
    this.auth.status = undefined;
  }
}
