import axios, {AxiosError} from 'axios';
import { API_ENDPOINT, endpoint } from './endpoint';
import { notification } from 'antd';

const contentType = 'application/ld+json';
export const axiosInstance = axios.create({
  timeout: 20000,
  baseURL: API_ENDPOINT,
  // responseType: contentType, // TODO
  headers: {
    Accept: contentType // TODO: Accept нету в описание аксиос
  }
})

export default {
  setupInterceptors: (store) => {
    axiosInstance.interceptors.response.use(
      response => response,
      (error: AxiosError) => {
        const originalRequest = error.config;

        // если ответили что не авторизован
        if (error?.response?.status === 401
          && /\/token\/refresh/.test(originalRequest.url)) {
          store.appStore.logout();
          return Promise.reject(error);
        }

        if (error.response && error.response.status === 401) {

          if (!originalRequest._retry) {
            originalRequest._retry = true;

            const refreshToken = window.localStorage.getItem('app.refreshToken');

            if (!refreshToken) {
              store.appStore.logout();
              return Promise.reject(error);
            }

            const data = new FormData();
            data.append('refresh_token', refreshToken);

            return (
              axiosInstance
                // запрос на refresh token
                .post(endpoint.refreshToken, data)
                .then((response) => {
                  // обновляем информацию по токену
                  window.localStorage.setItem('app.token', response.data.token);
                  window.localStorage.setItem('app.expiresIn', response.data.expires_in);
                  window.localStorage.setItem(
                    'app.refreshToken',
                    response.data.refresh_token
                  );

                  axiosInstance.defaults.headers.common.Authorization = `Bearer ${
                    response.data.token
                  }`;

                  originalRequest.headers.Authorization = `Bearer ${response.data.token}`;

                  return axiosInstance(originalRequest);
                })
                .catch((error) => {
                  notification.error({
                    message: 'Ошибка авторизации',
                    description: 'Не удалось обновить данные пользователя'
                  });
                  store.appStore.logout();
                  return Promise.reject(error);
                })
            );
          }
        }

        if (error.message === 'Network Error') {
          notification.error({
            message: 'Ошибка соединение с сервером',
            description: 'Сервер недоступен'
          });
        }

        return Promise.reject(error);
      }
    );
  }
};
