import {axiosInstance} from '../client'
import {endpoint} from '../endpoint'
import {serializeParamOrderRequest} from "@config/format";
import {ITask, ITaskStatus} from "@lib/entity";
import dayjs from "dayjs";

export type TaskFilter = {
  status?: string | number,
  id?: string | string[] | number | number[],
  'planedAt[after]': dayjs.Dayjs | string,
  'planedAt[before]': dayjs.Dayjs | string,
  enterprise: number,
  staff?: string;
}

export interface ITaskResponse {
  'hydra:member': ITask[],
  'hydra:totalItems': number
}

export interface ITaskStatusResponse {
  'hydra:member': ITaskStatus[],
  'hydra:totalItems': number
}

const status = {
  list: (order = {}, filter = {}, page = 1, itemsPerPage = 100) => axiosInstance({
    method: 'get',
    url: endpoint.tasks.status.list,
    params: {
      page,
      itemsPerPage,
      ...serializeParamOrderRequest(order),
      ...filter,
    },
  }),
  get: (id: number) => axiosInstance({
    method: 'get',
    url: endpoint.tasks.status.one(id),
  }),
}

const put = (id: number, fields: Partial<ITask>) => axiosInstance({
  method: 'put',
  url: endpoint.tasks.one(id),
  data: {
    ...fields,
  },
})

export default {
  list: (order = {}, filter: TaskFilter = {}, page = 1, itemsPerPage = 100) => axiosInstance<ITaskResponse>({
    method: 'get',
    url: endpoint.tasks.list,
    params: {
      page,
      itemsPerPage,
      ...serializeParamOrderRequest(order),
      ...filter,
    },
  }),
  get: (id: number) => axiosInstance({
    method: 'get',
    url: endpoint.tasks.one(id),
  }),
  put,
  create: (fields: ITask) => axiosInstance({
    method: 'post',
    url: endpoint.tasks.list,
    data: {
      ...fields,
    },
  }),
  status,
  changeStatus: (id: number, statusId: number) => status.get(statusId)
    .then((response) => put(id, {status: response.data['@id'] as string})),


}
