import React, {useCallback, useEffect, useState} from "react";
import {IStaff} from "@lib/entity";
import API from "@config";
import {IWorkLog} from "@lib/entity/IWorkLog";
import {Col, Dropdown, DropdownMenu, DropdownToggle, Row} from "reactstrap";
import SimpleBar from "simplebar-react";
import IncompleteWorkLog from "@pages/TasksPage/IncompleteWorkLog";
import {workLogEventChannel} from "@lib/eventBus/workLogEvents";
import dayjs from "dayjs";

const IncompleteWorkLogs = ({staff}: {
  staff: IStaff
}) => {

  const [workLogs, setWorkLogs] = useState<IWorkLog[]>([])
  const [menu, setMenu] = useState(false);

  const refreshData = useCallback(
    () => {
      if (staff) {
        API.worklog.list({}, {staff: staff['@id'], 'exists[endAt]': false}).then(response => {
          setWorkLogs(response.data['hydra:member'])
        })
      } else {
        setWorkLogs([]);
      }
    },
    [],
  );


  useEffect(() => {
    workLogEventChannel.on('onWorkLogDelete', refreshData)
    workLogEventChannel.on('onWorkLogChange', refreshData)

    return () => {
      workLogEventChannel.off('onWorkLogDelete', refreshData)
      workLogEventChannel.off('onWorkLogChange', refreshData)
    }
  }, []);

  useEffect(() => {
    refreshData()
  }, [staff]);

  const complete = (workLog: IWorkLog) => {
    const removed = workLogs.filter(y => y.id !== workLog.id);
    API.worklog.update(workLog.id, {
      endAt: dayjs().format(),
    }).then(() => setWorkLogs(removed))
  }

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="dropdown d-inline-block"
      tag="li"
    >
      <DropdownToggle
        className="btn header-item noti-icon waves-effect"
        tag="button"
        id="page-header-notifications-dropdown"
      >
        <i className="uil-brain"></i>
        {workLogs.length > 0 ?
          <span className="badge bg-danger rounded-pill">{workLogs.length}</span> : <></>}
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
        <div className="p-3">
          <Row className="align-items-center">
            <Col>
              <h6 className="m-0 font-size-16"> Незавершённые работы </h6>
            </Col>
          </Row>
        </div>
        <SimpleBar style={{height: "230px", padding: "20px"}}>
          {workLogs.map(x => <IncompleteWorkLog key={x.id} workLog={x} onStop={complete}/>)}
        </SimpleBar>
      </DropdownMenu>
    </Dropdown>
  )
}

export default IncompleteWorkLogs;
