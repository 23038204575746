import PropTypes from "prop-types";
import React, {useCallback, useEffect, useRef} from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import {Link, withRouter} from "react-router-dom";

//i18n
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";

interface IProps {
  appStore: Record<string, any>,
  location: any,
  t: any
}

const SidebarContent = inject("appStore")(observer(({appStore, location, t}: IProps) => {
  const ref = useRef();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const liItems = ul.getElementsByTagName("li");

    for (let i = 0; i < liItems.length; ++i) {
      liItems[i].classList.remove("mm-active");
    }

    const aItems = ul.getElementsByTagName("a");
    for (let i = 0; i < aItems.length; ++i) {
      aItems[i].classList.remove("active");
      aItems[i].classList.remove("mm-active");
    }

    const items = ul.getElementsByTagName("a");

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

  }, [location.pathname, activateParentDropdown]);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);


  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <>
      <SimpleBar style={{maxHeight: "100%"}} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title"><i className="uil-channel"></i> UNITASK</li>
            <li>
              <Link to="/tasks" className="waves-effect">
                <i className="uil-comment-alt-check"></i>
                <span>Задачи</span>
              </Link>

            </li>
            <li>
              <Link to="/dash" className=" waves-effect">
                <i className="uil-dashboard"></i>
                <span>Дашбоард</span>
              </Link>
            </li>
            <li>
              <Link to="/task_statuses" className=" waves-effect">
                <i className="uil-grids"></i>
                <span>Статусы</span>
              </Link>
            </li>
            <li>
              <Link to="/tags" className=" waves-effect">
                <i className="uil-tag"></i>
                <span>Тэги</span>
              </Link>
            </li>
            <li>
              <Link to="/work_time_report" className=" waves-effect">
                <i className="uil-chart-growth"></i>
                <span>Отчёты</span>
              </Link>
            </li>

            {appStore.auth.roles.includes("ROLE_SUPER_ADMIN") && (
              <>
                <li className="menu-title"><i className="uil-channel"></i> UNIPIM</li>
                <li>
                  <Link to="/products" className=" waves-effect">
                    <i className="uil-shop"></i>
                    <span>Номенклатура</span>
                  </Link>
                </li>
                <li>
                  <Link to="/manufacturers" className=" waves-effect">
                    <i className="uil-building"></i>
                    <span>Производители</span>
                  </Link>
                </li>
                <li>
                  <Link to="/brands" className=" waves-effect">
                    <i className="uil-dialpad"></i>
                    <span>Брэнды</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="uil-box"></i>
                    <span>Упаковки</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/pack_kinds" className=" waves-effect">
                        <i className="uil-glass-tea"></i>
                        <span>Виды упаковок</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/pack_types" className=" waves-effect">
                        <i className="uil-box"></i>
                        <span>Типы упаковок</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}

            <li className="menu-title"><i className="uil-channel"></i> UNIDOC</li>

            {(appStore.auth.roles.includes("ROLE_SUPER_ADMIN") || appStore.auth.roles.includes("ROLE_OWNER")) && (
              <>
                <li className="">
                  <hr/>
                </li>
                <li className="menu-title"><i className="uil-setting"></i> Настройки</li>
                <li>
                  <li>
                    <Link to={"/enterprises"} className="waves-effect">
                      <i className="uil-building"></i>
                      Предприятия
                    </Link>
                  </li>
                  <li>
                    <Link to={"/companies"} className="waves-effect">
                      <i className="uil-bag"></i>
                      Компании
                    </Link>
                  </li>
                  <li>
                    <Link to={"/contacts"} className="waves-effect">
                      <i className="uil-at"></i>
                      Контакты
                    </Link>
                  </li>
                  <li>
                    <Link to={"/staffs"} className="waves-effect">
                      <i className="uil-house-user"></i>
                      Сотрудники
                    </Link>
                  </li>
                  <li>
                    <Link to={"/groups"} className="waves-effect">
                      <i className="uil-users-alt"></i>
                      Группы пользователей
                    </Link>
                  </li>


                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </>
  );
}));

SidebarContent.wrappedComponent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
