import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
//i18n
import { withTranslation } from "react-i18next";
import { axiosInstance } from "@config/client";
import { endpoint } from "@config/endpoint";
import moment from "moment";

interface INotification {
  id: number,
  createdAt: string,
  title?: string,
  message?: string,
  type?: string
}

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [notifications, setNotifications] = useState([] as INotification[]);

  const onRead = (notificationId) => {
    axiosInstance.put(endpoint.notifications.one(notificationId), {
      seen: true,
    }).then((response: any) => {
      axiosInstance.get(endpoint.notifications.list, {
        params: {
          seen: false,
        },
      }).then((response: any) => {
        setNotifications(response.data["hydra:member"]);
      });
    });
  };

  useEffect(() => {
    axiosInstance.get(endpoint.notifications.list, {
      params: {
        seen: false,
      },
    }).then((response: any) => {
      setNotifications(response.data["hydra:member"]);
    });
  }, []);

  const notificationsBlock = notifications.map((notification) => (
    <Link key={notification.id} to="" className="text-reset notification-item">
      <div className="d-flex align-items-start">
        <div className="avatar-xs me-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                  <i className="uil-shopping-basket"></i>
                  </span>
        </div>
        <div className="flex-1">
          <h6 className="mt-0 mb-1">
            {notification.title}
          </h6>
          <div className="font-size-12 text-muted">
            <p className="mb-1">
              {notification.message}
            </p>
            <p className="mb-0">
              <i className="mdi mdi-clock-outline"></i>
              {moment(notification.createdAt).format("DD.MM.Y kk:mm")}
            </p>
          </div>
        </div>
      </div>
    </Link>
  ));

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="uil-bell"></i>
          {notifications.length > 0 ?
            <span className="badge bg-danger rounded-pill">{notifications.length}</span> : <></>}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16"> Уведомления </h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small">
                  {" "}
                  Всё прочтено
                </a>
              </div>
            </Row>
          </div>
          <SimpleBar style={{ height: "230px" }}>
            {notifications.length > 0 ? notificationsBlock : <></>}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
