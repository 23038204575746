import {inject, observer} from "mobx-react";
import React from "react";
import LoginLayout from "@components/LayoutComponents/Layout/LoginLayout"
import VerticalLayout from "@components/LayoutComponents/Layout/VerticalLayout"

interface IProps {
  appStore: Record<string, any>
}

const Layout = inject("appStore")(observer(({appStore}: IProps) => {
  return (
      appStore.auth.isLoggedIn ? <VerticalLayout/> : <LoginLayout/>
  );
}))

export default Layout;
