import { inject, observer } from 'mobx-react'
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { homeRoute } from '.'


export default inject("appStore")(observer(({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (rest.appStore.auth.isLoggedIn !== true ? <Component {...props} /> : <Redirect to={homeRoute} />)}
  />
)))
