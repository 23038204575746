import PropTypes from "prop-types";
import React, { useState } from "react";

// Reactstrap
import { Button, Dropdown, DropdownMenu, DropdownToggle, Form, Input } from "reactstrap";
import { Link } from "react-router-dom";
// Import menuDropdown
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//i18n
import { withTranslation } from "react-i18next";


import logoSm from "../../../../assets/images/logo-sm.png";
import logoDark from "../../../../assets/images/logo-dark.png";
import logoLight from "../../../../assets/images/logo-light.png";
import NotificationDropdown from "@components/NotificationDropdown";
import {ProfileMenu} from "@components/ProfileMenu";
import IncompleteWorkLogs from "@pages/TasksPage/IncompleteWorkLogs";
import {inject, observer} from "mobx-react";
import MercureSubscriber from "@components/Mercure/MercureSubscriber";
import {MERCURE_HUB} from "@config/endpoint";
import {workLogEventChannel} from "@lib/eventBus/workLogEvents";
import {eventBus} from "@lib/eventBus";
import {TaskNotification} from "@components/Mercure/TaskNotification";


const Header = inject("appStore")(observer(({appStore}) => {
  const [search, setsearch] = useState(false);

  function tToggle() {
    const body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  workLogEventChannel.on('onWorkLogChange', (payload) => {
    console.log('onWorkLogChange')
  })

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="20" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="20" />
                </span>
              </Link>
            </div>
          </div>

          <div className="d-flex">
            <Dropdown
              className="d-inline-block d-lg-none ms-2"
              onClick={() => {
                setsearch(!search);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                variant="button"
              > <i className="uil-search" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <Input type="text" className="form-control" placeholder="Search ..."
                            aria-label="Recipient's username" />
                      <div className="input-group-append">
                        <Button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>
            <IncompleteWorkLogs staff={appStore.auth.staff} />
            <NotificationDropdown />
            <ProfileMenu/>
          </div>
        </div>
      </header>
      <MercureSubscriber
          hub={MERCURE_HUB!}
          topics={[
            `*`,
          ]}
          onMessage={{
            '' : (message) => {
              console.log(message)
              eventBus.emit('onMercureMessageReceive', message);
            }
          }}
          token={`${appStore.auth.token}`}
      />
      <TaskNotification />
    </React.Fragment>
  )
}))

export default Header;
