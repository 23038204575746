import Reference from "@components/Reference";
import {ITask} from "@lib/entity";
import React from "react";

const IncompleteWorkLog = ({workLog, onStop}) => {
  return (
    <div>
      <Reference<ITask> documentUri={workLog.task} render={(document) => document.name}/>
      <i onClick={(e) => onStop(workLog)} style={{color: '#f5222d', marginLeft: '10px', cursor: 'pointer'}} className="uil-stop-circle"></i>
    </div>
  )
}

export default IncompleteWorkLog;
