import {eventbus} from "@lib/eventBus/eventbus";
import {IWorkLog} from "@lib/entity/IWorkLog";
import {eventBus} from "@lib/eventBus/index";

export const workLogEventChannel = eventbus<{
  onWorkLogChange: (payload: IWorkLog) => void
  onWorkLogDelete: (iri: string) => void
}>()

eventBus.on('onMercureMessageReceive', (message: Partial<IWorkLog>) => {
  if (message["@type"] === 'WorkLog') { //create || update
    workLogEventChannel.emit("onWorkLogChange", message as IWorkLog)
  }

  if (message['@type'] === undefined && message['@id'] && message['@id']?.includes('work_log')) { //delete
    workLogEventChannel.emit('onWorkLogDelete', message['@id'])
  }
})
