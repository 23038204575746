import {makeAutoObservable} from 'mobx'
import AppStore from "./appStore"

interface IMainStore {
  // protected appStore: any
}

class MainStore implements IMainStore {
  appStore = new AppStore()

  constructor() {
    // Инициализация дочерних хранилищ
    
    makeAutoObservable(this)
  }
}

export default new MainStore()
