import { axiosInstance } from '../client'
import { endpoint } from '../endpoint'
import {serializeParamOrderRequest} from "@config/format";
import {ITag} from "@lib/entity";

export type TagFilter = {
  staff?: string | number,
  task?: string | number,
  id?: string | string[] | number | number[]
}

export interface ITagResponse {
  'hydra:member': ITag[],
  'hydra:totalItems': number
}

export default {
  list: (order = {}, filter: TagFilter = {}, page = 1, itemsPerPage = 100) => axiosInstance<ITagResponse>({
    method: 'get',
    url: endpoint.tags.list,
    params: {
      page,
      itemsPerPage,
      ...serializeParamOrderRequest(order),
      ...filter,
    },
  }),
  get: (id: number) => axiosInstance({
    method: 'get',
    url: endpoint.tags.one(id),
  }),
  create: (fields: ITag)  => axiosInstance({
    method: 'post',
    url: endpoint.tags.list,
    data: {
      ...fields,
    },
  }),
}
