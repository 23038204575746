import React from "react";
import "../style.scss";
import Routes from "../../../../routes";

const MainLayout = () => {
  return (
    <Routes />
  );
};

export default MainLayout;
