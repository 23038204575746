import React from "react";
import { Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
// import LoginPage from '@pages/DefaultPages/LoginPage';
import NotFoundPage from "@pages/DefaultPages/NotFoundPage";
// import CompaniesPage from '@pages/CompaniesPage';
import PrivateRoute from "./PrivateRoute";
import LoginRoute from "./LoginRoute";

const loadable = function(loader: () => Promise<any>) {
  return Loadable({
    loader,
    delay: false,
    loading: () => null,
  });
};

export const homeRoute = "/dash";

const loadableRoutes = {
  "/": {
    component: loadable(() => import("@pages/DefaultPages/LoginPage")),
    isPrivate: false,
  },
  "/login": {
    component: loadable(() => import("@pages/DefaultPages/LoginPage")),
    isPrivate: false,
  },
  "/register": {
    component: loadable(() => import("@pages/DefaultPages/RegisterPage")),
    isPrivate: false,
  },
  "/dash": {
    component: loadable(() => import("@pages/DashboardPage")),
    isPrivate: true,
  },
  // "/kanban": {
  //   component: loadable(() => import("@pages/KanbanPage")),
  //   isPrivate: true,
  // },
  "/companies": {
    component: loadable(() => import("@pages/CompaniesPage")),
    isPrivate: true,
  },
  "/companies/add": {
    component: loadable(() => import("@pages/CompaniesPage/CompanyEdit")),
    isPrivate: true,
  },
  "/companies/:id?/edit": {
    component: loadable(() => import("@pages/CompaniesPage/CompanyEdit")),
    isPrivate: true,
  },
  "/enterprises": {
    component: loadable(() => import("@pages/EnterprisesPage")),
    isPrivate: true,
  },
  "/enterprises/add": {
    component: loadable(() => import("@pages/EnterprisesPage/EnterpriseEdit")),
    isPrivate: true,
  },
  "/enterprises/:id?/edit": {
    component: loadable(() => import("@pages/EnterprisesPage/EnterpriseEdit")),
    isPrivate: true,
  },
  "/contacts": {
    component: loadable(() => import("@pages/ContactsPage")),
    isPrivate: true,
  },
  "/staffs": {
    component: loadable(() => import("@pages/StaffsPage")),
    isPrivate: true,
  },
  "/staffs/add": {
    component: loadable(() => import("@pages/StaffsPage/StaffView/StaffEdit")),
    isPrivate: true,
  },
  "/staff/:id?": {
    component: loadable(() => import("@pages/StaffsPage/StaffView")),
    isPrivate: true,
  },
  "/staffs/:id?": {
    component: loadable(() => import("@pages/StaffsPage/StaffView")),
    isPrivate: true,
  },
  "/staffs/:id?/edit": {
    component: loadable(() => import("@pages/StaffsPage/StaffView/StaffEdit")),
    isPrivate: true,
  },
  "/tasks": {
    component: loadable(() => import("@pages/TasksPage")),
    isPrivate: true,
  },
  "/task_statuses": {
    component: loadable(() => import("@pages/TaskStatusesPage")),
    isPrivate: true,
  },
  "/tags": {
    component: loadable(() => import("@pages/TagsPage")),
    isPrivate: true,
  },
  "/work_time_report": {
    component: loadable(() => import("@pages/WorkTimeReportPage")),
    isPrivate: true,
  },
  "/tasks/kanban": {
    component: loadable(() => import("@pages/TasksPage/TasksKanban")),
    isPrivate: true,
  },
  "/tasks/calendar": {
    component: loadable(() => import("@pages/TasksPage/CalendarTask")),
    isPrivate: true,
  },
  "/tasks/new": {
    component: loadable(() => import("@pages/TasksPage/TaskView/TaskEdit")),
    isPrivate: true,
  },
  "/tasks/:id?": {
    component: loadable(() => import("@pages/TasksPage/TaskView")),
    isPrivate: true,
  },
  "/tasks/:id?/edit": {
    component: loadable(() => import("@pages/TasksPage/TaskView/TaskEdit")),
    isPrivate: true,
  },
  "/users": {
    component: loadable(() => import("@pages/UsersPage")),
    isPrivate: true,
  },
  "/users/:id?": {
    component: loadable(() => import("@pages/UsersPage/UserView")),
    isPrivate: true,
  },
  "/groups": {
    component: loadable(() => import("@pages/GroupsPage")),
    isPrivate: true,
  },
  "/groups/:id?": {
    component: loadable(() => import("@pages/GroupsPage/GroupView")),
    isPrivate: true,
  },
  "/pack_kinds/:id?": {
    component: loadable(() => import("@pages/PackKindPage")),
    isPrivate: true,
  },
  "/pack_types/:id?": {
    component: loadable(() => import("@pages/PackTypePage")),
    isPrivate: true,
  },
  "/products/": {
    component: loadable(() => import("@pages/ProductPage")),
    isPrivate: true,
  },
  "/products/:id?": {
    component: loadable(() => import("@pages/ProductPage/ProductView")),
    isPrivate: true,
  },
  "/products/:id/edit": {
    component: loadable(() => import("@pages/ProductPage/ProductView/ProductEdit")),
    isPrivate: true,
  },
  "/manufacturers": {
    component: loadable(() => import("@pages/ManufacturerPage")),
    isPrivate: true,
  },
  "/brands": {
    component: loadable(() => import("@pages/BrandPage")),
    isPrivate: true,
  },
  "/brands/:id?": {
    component: loadable(() => import("@pages/BrandPage/BrandView")),
    isPrivate: true,
  },
  "/brands/:id?/edit": {
    component: loadable(() => import("@pages/BrandPage/BrandEdit")),
    isPrivate: true,
  },
  "/brandlines/:id?": {
    component: loadable(() => import("@pages/BrandPage/BrandlineView")),
    isPrivate: true,
  },
  "/brandlines/:id?/edit": {
    component: loadable(() => import("@pages/BrandPage/BrandlineEdit")),
    isPrivate: true,
  },
};

const Routes = () => {
  return (
    <Switch>
      {
        Object.keys(loadableRoutes).map((path: string) => {
          const { exact, ...props } = loadableRoutes[path];
          props.exact = exact === void 0 || exact || false; // set true as default
          return props.isPrivate ? (
            <PrivateRoute key={path} path={path} {...props} />
          ) : (
            <LoginRoute key={path} path={path} {...props} />
          );
        })
      }
      <Route render={() => <NotFoundPage />} />
    </Switch>
  );
};

export default Routes;
