import React from "react"
import { Container, Row, Col } from "reactstrap"
import * as process from "process";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © Uniapp.</Col>
            <Col sm={6} className={'d-flex justify-content-sm-end'}>Версия: {process.env.VERSION}</Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
