
import worklog from './services/worklog'
import tag from './services/tags'
import task from './services/tasks'

const API = {
  worklog,
  tag,
  task
}

export default API
