class OptionsStore {
  colors = {
    main: "#33A3F4",
    secondary: "#949494",
    underlayer: "#FBFBFB",
    error: "#f5f5f5",
  }
  timeSlotDuration = 15
  isDisabledSlotVisible = true
  reservationRange = 30
}

export default new OptionsStore()
