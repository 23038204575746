import React, {useState, useEffect, FunctionComponent} from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import AppStore from "@stores/appStore";
import {IReactComponent} from "mobx-react/dist/types/IReactComponent";

interface IProps {
  appStore: AppStore,
}


export const ProfileMenu =
  inject("appStore")(observer(({appStore}: IProps) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  useEffect(() => {
  }, [])

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <i className="uil-user rounded-circle font-size-22"></i>
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{appStore.auth.login}</span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <a href={'#'} onClick={() => appStore.logout()} className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>Выход</span>
          </a>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}))
