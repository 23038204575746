import React, {useCallback, useEffect, useMemo} from "react";
import {taskEventChannel} from "@lib/eventBus/taskEvents";
import {notification} from "antd";
import {ITask} from "@lib/entity";
import {inject, observer} from "mobx-react";
import parse from "html-react-parser";

export const TaskNotification = inject("appStore")(observer(({appStore}: { appStore: Record<string, any> }) => {

  const appStaff = appStore.auth.staff;

  const notifyAboutTask = useCallback(
    (task: ITask) => {

      if (!appStaff) {
        return;
      }

      const isNewTask = task.createdAt === task.updatedAt;
      const isExecutor = task.executor['@id'] === appStaff['@id'];
      const isCoExecutor = task.coExecutor?.find(x=>x['@id'] === appStaff['@id']);
      const isObserver = task.observer?.find(x=>x['@id'] === appStaff['@id']);

      let message = '';

      if (isExecutor) {
        message = (isNewTask ? 'Добавлена новая' : 'Отредактирована') +  ` задача <a href="${task['@id']}">#${task['id']}</a>` + ', в которой вы являетесь исполнителем';
      } else if (isCoExecutor) {
        message = (isNewTask ? 'Добавлена новая' : 'Отредактирована') +  ` задача <a href="${task['@id']}">#${task['id']}</a>` + ', в которой вы являетесь соисполнителем';
      } else if (isObserver) {
        message = (isNewTask ? 'Добавлена новая' : 'Отредактирована') +  ` задача <a href="${task['@id']}">#${task['id']}</a>` + ', в которой вы являетесь наблюдателем';
      }

      if (message) {
        notification.open({
          message: '',
          description: parse(message),
          placement: "topRight",
        });
      }
    },
    [appStaff],
  );

  useEffect(() => {
    taskEventChannel.on('onTaskChange', notifyAboutTask)
    // taskEventChannel.on('onTaskDelete', notifyAboutTask)

    return () => {
      taskEventChannel.off('onTaskChange', notifyAboutTask)
      // taskEventChannel.off('onTaskDelete', notifyAboutTask)
    }
  }, []);

  return (
    <></>
  );
}));
