import React, {useEffect, useState} from "react";
import {axiosInstance} from "@config/client";
import {Link} from "react-router-dom";
import {Skeleton, Spin} from "antd";

export default function Reference<T>({
                                       documentUri,
                                       render,
                                     }: {
  documentUri: string,
  render?: (document: T) => Element | JSX.Element
}): JSX.Element {

  const [document, setDocument] = useState(null as null | T);

  useEffect(() => {
    axiosInstance.get(documentUri).then(response => {
      setDocument(response.data);
    });
  }, [documentUri]);

  return (
    document ? (
        render ?
          <Link to={documentUri}>{render(document)}</Link> :
          <Link to={documentUri}>{documentUri}</Link>
      ) :
      <Spin></Spin>
  );
}
